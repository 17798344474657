<template>
  <div class="home">
     <h1>Please wait...</h1>
  </div>
</template>

<script>

export default {
  name: 'Home',
  created() {
    this.saveSpotifyTokens(this.$router.currentRoute);
  },
  methods: {
    saveSpotifyTokens(url) {
      console.log(url);
      if (url.path === '/callback') {
        const hash = url.hash;
        const result = hash.split('&').reduce(function (res, item) {
          const parts = item.split('=');
          res[parts[0]] = parts[1];
          return res;
        }, {});
        console.log(result)
        const authToken = result['#access_token'];
        window.location = `http://${decodeURIComponent(result.state)}/callback#access_token=${authToken}`;
      }
    }
  }
}
</script>
